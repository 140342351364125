import React, { useEffect, useState, createContext } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Homepage from "./uiComponents/Homepage";
import Homepage1 from "./uiComponents/Homepage1";
import Homepage2 from "./uiComponents/Homepage2";
import "bootstrap-icons/font/bootstrap-icons.css";
import Landing from "./uiComponents/Landing";
import Landing1 from "./uiComponents/Landing1";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";
import Addcase from "./uiComponents/Addcase";
import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import ViewGame2 from "./uiComponents/ViewGame2";
import Gamehistory from "./uiComponents/Gamehistory";
import "animate.css";
import axios from "axios";
import Transactionhistory from "./uiComponents/Transactionhistory";
import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Rightcontainer from "./Components/Rightcontainer";
import Kyc from "./uiComponents/Kyc";
import Kyc2 from "./uiComponents/Kyc2";
import Kyc3 from "./uiComponents/Kyc3";
import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";
import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";

export const WebSocketContext = createContext();

const App2 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const wssurl = process.env.REACT_APP_SOCKETURL;

  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const location = useLocation();

  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (access_token) {
      axios
        .get(baseUrl + `me`, { headers })
        .then((res) => {
          setUser(res.data);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            // history.push("/login");
          }
        });
    }
  };
  const connectWebSocket = () => {
    const access_token = localStorage.getItem("token");
    if (access_token) {
      const ws = new WebSocket(wssurl + "/server?t=" + access_token);

      ws.onopen = () => {
        console.log("WebSocket connection opened");

        // Send a ping message every 30 seconds to keep the connection alive
        const pingInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ event: "ping" }));
          }
        }, 30000); // 30 seconds

        // Store the interval ID to clear it later if needed
        ws.pingInterval = pingInterval;
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed, attempting to reconnect...");
        if (ws.pingInterval) {
          clearInterval(ws.pingInterval); // Clear the ping interval
        }
        setTimeout(() => connectWebSocket(), 5000); // Attempt to reconnect after 5 seconds
      };

      ws.onerror = (error) => {
        console.error("WebSocket error", error);
      };

      setSocket(ws);

      return () => {
        if (ws.pingInterval) {
          clearInterval(ws.pingInterval); // Clear the ping interval on cleanup
        }
        ws.close();
      };
    }
  };

  useEffect(() => {
    connectWebSocket();
    getUser();
    // eslint-disable-next-line
  }, []);

  const isWebgame = location.pathname.includes("/webgame");

  return (
    <WebSocketContext.Provider value={socket}>
      {!isWebgame && <Header user={user} />}
      {!access_token ? (
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/landing" component={Landing} />
          <Route path="/login" component={userLogin} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          <Route path="/about" component={AboutUs} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/contact-us" component={Support} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Redirect to="/" /> {/* Redirect for any undefined routes */}
        </Switch>
      ) : (
        <Switch>
          <Route path="/transaction-history" component={Transactionhistory} />
          <Route exact path="/Referral-history" component={Referralhis} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/Gamehistory" component={Gamehistory} />
          <Route
            exact
            path="/HomePage/:Game"
            render={() => <Homepage walletUpdate={getUser} socket={socket} />}
          />
          <Route
            exact
            path="/HomePage1/:Game"
            render={() => <Homepage1 walletUpdate={getUser} socket={socket} />}
          />
          <Route
            exact
            path="/HomePage2/:Game"
            render={() => <Homepage2 walletUpdate={getUser} socket={socket} />}
          />
          <Route exact path="/refer" component={Refer} />
          <Route exact path="/Notification" component={Notification} />
          <Route exact path="/" component={Landing} />
          <Route path="/profile" component={Profile1} />
          <Route path="/viewgame1/:id" component={ViewGame1} />
          <Route path="/webgame/:id" component={ViewGame2} />
          <Route
            path="/addcase"
            render={() => <Addcase walletUpdate={getUser} />}
          />
          <Route
            path="/Withdrawopt"
            render={() => <Withdrawopt walletUpdate={getUser} />}
          />
          <Route path="/wallet" component={Mywallet} />
          <Route path="/support" component={Support} />
          <Route exact path="/landing/:id" component={Landing} />
          <Route path="/kyc" render={() => <Kyc />} />
          <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
          <Route path="/kyc3" render={() => <Kyc3 user={user} />} />
          <Route path="/Rules" component={Gamerules} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          <Route
            path="/return"
            render={() => <Return walletUpdate={getUser} />}
          />
          <Route path="/notify" component={Notify} />
          <Route
            path="/Redeem"
            render={() => <Redeem walletUpdate={getUser} />}
          />
          <Route path="/contact-us" component={Support} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Route path="/about" component={AboutUs} />
          <Redirect to="/" /> {/* Redirect for any undefined routes */}
        </Switch>
      )}
      {/* {!isWebgame && <Footer />} */}
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </WebSocketContext.Provider>
  );
};

export default App2;
